import React from "react"
import { graphql } from "gatsby"
import GridLayout from "../Layout/GridLayout"
import { useI18next } from "../i18n"

import { Breadcrumbs } from "../Layout/Components"
import Blog, { PostData, CategoryData, TagData } from "../rows/Blog"

import { Seo } from "../seo"
import siteMap from "../sitemap"

export default (props) => {
  const { t } = useI18next()
  const category = props.data.wordPress.category
  const posts: PostData[] = props.data.wordPress.posts.nodes
  const recentPosts: PostData[] = props.data.wordPress.recentPosts.nodes
  const categories: CategoryData[] = props.data.wordPress.categories.nodes
  const tags: TagData[] = props.data.wordPress.tags.nodes

  //console.log("Props: ", props)
  //console.log("Context: ", props.pageContext)

  return (
    <GridLayout>
      <Seo title={t("Blog")} description={category.description} />
      <Breadcrumbs data={[{ to: "/", label: t("Home") }, { label: t("Blog") }]} />

      <Blog
        title={t("Blog")}
        description={category.description}
        image={category.details.featuredImage}
        posts={posts}
        categories={categories}
        tags={tags}
        allPosts={recentPosts}
      />
    </GridLayout>
  )
}

//query($id: ID!, $tags: [String!]) {
export const query = graphql`
  query($language: ID!, $languageCode: Wordpress_LanguageCodeFilterEnum!) {
    wordPress {
      category(idType: SLUG, id: $language) {
        details {
          featuredImage {
            ...imageData
          }
        }
        description
      }
      posts(where: { orderby: { field: DATE, order: DESC } }, first: 1000) {
        nodes {
          ...post
        }
      }
      ...recentPosts
      ...sidebarData
    }
  }
`
